export default class ScrollSpy {
	constructor( root, settings = {} ) {
		settings = Object.assign( {
			target: '.has-scrollspy',
			offset: 10,
		}, settings );
		this.offset = settings.offset;

		this.spyRoot = typeof settings.target === 'string' ? document.querySelector( settings.target ) : settings.target;
		this.targets = this.spyRoot.querySelectorAll( "[href*='#']" );
		this.pairs = [];

		for ( const base of this.targets ) {
			const targetURL = '#' + base.href.split( '#' )[ 1 ];

			if ( targetURL === '#' ) {
				continue;
			}

			const target = document.querySelector( targetURL );
			if ( target ) {
				this.pairs.push( [ base, target ] );
			}
		}

		this.root = root;

		this.root.addEventListener( 'scroll', () => {
			this.update( this.getScroll() );
		} );
	}

	update( scroll ) {
		let active = null;
		for ( const [ base, target ] of this.pairs ) {
			const top = target.getBoundingClientRect().top + scroll;

			if ( scroll >= top - this.offset ) {
				active = base;
			}
			base.classList.remove( 'active' );
		}
		if ( active ) {
			active.classList.add( 'active' );
		}
	}

	getScroll() {
		if ( this.root instanceof Window ) {
			return this.root.scrollY;
		}
		if ( this.root instanceof HTMLElement ) {
			return this.root.scrollTop;
		}
	}
}
