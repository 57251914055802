export default class StripeEffect {
	constructor( elements, settings ) {
		const defaultSettings = {
			time: 500,
			outDelay: 0,
			class: 'has-stripe',
			inverted: false,
		};
		settings = Object.assign( defaultSettings, settings );
		if ( typeof elements === 'string' ) {
			this.elements = document.querySelectorAll( elements );
		} else if ( elements instanceof HTMLElement ) {
			this.elements = [ elements ];
		} else if ( elements instanceof NodeList ) {
			this.elements = elements;
		}
		this.effects = {};
		let index = 0;
		this.time = settings.time;
		this.outDelay = settings.outDelay;
		this.class = settings.class;
		this.inverted = settings.inverted;
		for ( const element of this.elements ) {
			element.classList.add( this.class );
			element.dataset.effectIndex = index;
			if ( ! this.inverted ) {
				element.addEventListener( 'mouseenter', () => this.enter( element ) );
				element.addEventListener( 'mouseleave', () => this.leave( element ) );
			} else {
				element.addEventListener( 'mouseenter', () => this.leave( element ) );
				element.addEventListener( 'mouseleave', () => this.enter( element ) );
			}
			index++;

			if ( this.inverted ) {
				this.enter( element );
			}
		}
	}

	enter( element ) {
		const span = document.createElement( 'span' );
		span.classList.add( 'effect' );
		span.style.transitionDuration = ( this.time / 1000 ) + 's';
		element.appendChild( span );
		this.effects[ element.dataset.effectIndex ] = span;
		setTimeout( () => {
			span.classList.add( 'animate-in' );
		}, 10 );
	}

	leave( element ) {
		const index = element.dataset.effectIndex;
		if ( index in this.effects ) {
			const span = this.effects[ index ];
			setTimeout( () => {
				span.classList.add( 'animate-out' );
			}, this.outDelay );
			setTimeout( () => {
				span.parentNode.removeChild( span );
			}, this.time + this.outDelay );
		}
	}
}
