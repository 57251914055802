export default class Drilldown {
	constructor( element, settings = {} ) {
		settings = Object.assign( {
			submenuClass: 'has-submenu',
			recursive: true,
			autoParentLink: true,
			buttonAfter: '',
			buttonBefore: '',
			backBefore: '',
			backAfter: '',
			backButtonText: 'Terug',
		}, settings );
		this.submenuClass = settings.submenuClass;
		this.recursive = settings.recursive;
		this.autoParentLink = settings.autoParentLink;
		this.buttonBefore = settings.buttonBefore;
		this.buttonAfter = settings.buttonAfter;
		this.backBefore = settings.backBefore;
		this.backAfter = settings.backAfter;
		this.backButtonText = settings.backButtonText;

		// allow queryString, element, or nodeList
		let root = false;
		if ( typeof element === 'string' ) {
			root = document.querySelector( element );
		}
		if ( element instanceof NodeList ) {
			root = element[ 0 ];
		}
		if ( element instanceof HTMLElement ) {
			root = element;
		}

		if ( ! root ) {
			throw new Error( 'Could not find drilldown element' );
		}

		// get root
		this.root = false;
		if ( root.tagName === 'UL' ) {
			this.root = root;
		}

		// handle errors
		if ( ! this.root ) {
			throw new Error( 'Could not find ul in drilldown' );
		}

		// setup the ul
		this.setupList( this.root );

		//make root focussable
		this.root.setAttribute( 'tabindex', 0 );

		// setup initial height
		const box = this.root.getBoundingClientRect();
		this.root.style.height = box.height + 'px';
		this.root.style.setProperty( '--drilldown-height', box.height + 'px' );

		this.root.dataset.height = box.height + 'px';

		// setup dynamic height
		document.addEventListener( 'focusin', ( e ) => {
			if ( this.root.contains( e.target ) ) {
				this.updateHeight( e.target );
			}
		} );
	}

	update() {
		const active = document.activeElement;
		this.updateHeight( active );
	}

	setupList( ul ) {
		ul.classList.add( 'drilldown' );
		for ( const li of ul.children ) {
			// handle errors
			if ( li.tagName !== 'LI' ) {
				throw new Error( 'Invalid list item in ul' );
			}

			// get link and submenu
			let link, submenu;
			for ( const child of li.children ) {
				if ( child.tagName === 'A' ) {
					link = child;
				}
				if ( child.tagName === 'UL' ) {
					submenu = child;
				}
			}

			// when the list item has a submenu
			if ( submenu && ! li.classList.contains( this.submenuClass ) ) {
				// replace link with button
				const button = document.createElement( 'button' );
				button.innerHTML = this.buttonBefore + link.textContent + this.buttonAfter;
				li.prepend( button );

				// add link to submenu
				// let url = link.href;
				if ( new URL( link.href ).pathname !== window.location.pathname && this.autoParentLink ) {
					const listItem = document.createElement( 'li' );
					listItem.append( link );
					submenu.prepend( listItem );
				} else {
					link.remove();
				}

				// add back link to submenu
				const backListItem = document.createElement( 'li' );
				const backButton = document.createElement( 'button' );
				backButton.innerHTML = this.backBefore + this.backButtonText + this.backAfter;
				backButton.classList.add( 'drilldown-back' );
				backListItem.append( backButton );
				submenu.prepend( backListItem );
				backButton.addEventListener( 'click', () => this.focusBack( submenu ) );

				// make submenu focussable
				submenu.setAttribute( 'tabindex', 0 );

				// add class to the list item with submenu
				li.classList.add( this.submenuClass );
				li.setAttribute( 'tabindex', 0 );
				li.style.outline = 'none';

				// recursively setup the containing submenu
				if ( this.recursive ) {
					this.setupList( submenu );
				}
			}
		}
	}

	getChildHeight( ul ) {
		let total = 0;
		for ( const child of ul.children ) {
			total += child.getBoundingClientRect().height;
		}
		return total;
	}

	updateHeight( target ) {
		const parent = target.closest( 'li' );
		if ( parent ) {
			const ul = parent.children[ parent.children.length - 1 ];
			if ( ul.nodeName === 'UL' ) {
				// let ul = target.closest("ul");
				const height = this.getChildHeight( ul );
				this.root.style.height = height + 'px';
				this.root.style.setProperty( '--drilldown-height', height + 'px' );
			}
		} else {
			// let ul = target.closest("ul");
			this.root.style.height = this.root.dataset.height;
		}
	}

	focusBack( submenu ) {
		const parent = submenu.parentNode.closest( 'ul' );
		if ( parent ) {
			// let a = parent.querySelector("a, button");
			parent.focus( {
				preventScroll: true,
			} );
			this.resetScroll();
		} else {
			this.root.focus();
		}
	}

	resetScroll() {
		const uls = this.root.querySelectorAll( 'ul' );
		for ( const ul of uls ) {
			ul.scrollTo( 0, 0 );
		}
	}
}
